<template>
  <section class="success">
    <popup-base
      v-show="wantToMakeOffer"
      class="popo md:pt-10 md:pb-4 w-full"
      @clickOnBackground="logState"
    >
      <div class="cardinal mr-auto ml-auto">
        <div
          class="success-container h-full p-6"
        >
          <div v-if="!succesSend">
            <div class="titre text-center">Token de payement </div>

            <div class="sous-titre mt-6">
              <span v-if="position === 'MOBILE'">Veuillez entrer votre token de payement</span>
            </div>

            <input v-model="numero" type="text" @keypress="isNumberOnly" class="w-full h-full input mt-4"/>

            <div class="">
              <div class="button continuer mt-6 w-full" @click="send">Envoyer</div>
            </div>
          </div>
        </div>
      </div>
    </popup-base>
  </section>
</template>

<script>
import PopupBase from '../helper/add/popupfat'

export default {
  name: 'Success',
  components: { PopupBase },
  props: {
    wantToMakeOffer: {
      type: Boolean,
      default: false
    },

    donnes: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      numero: '',
      succesSend: false,
      position: 'MOBILE'
    }
  },
  computed: {
  },

  methods: {
    fermer () {
      this.$emit('oga', false)
    },

    send () {
      if (this.numero.length > 1) {
        this.$emit('info', this.numero)
        this.$emit('oga', false)
      }
    },

    logState (answer) {
      this.wantToMakeOffer = answer
      this.$emit('oga', false)
    },

    isNumberOnly ($event) {
      const keyCode = ($event.keyCode ? $event.keyCode : $event.which)
      if ((keyCode < 42 || keyCode > 57) && keyCode !== 46) { // 46 is dot
        $event.preventDefault()
      }
    }
  }
}
</script>

<style lang="scss"  scoped>
    @import "../../assets/style/sass/mixin";
    .success{
      z-index: 999;
    }
    .cardinal{
      width: 50%;
    }
.bloc{
  background: #FFFFFF;
  border: 0.604762px solid #909090;
  box-sizing: border-box;
  border-radius: 18.1429px;
  height: 121px;
  cursor: pointer;
  .nom{
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    text-align: center;
    color: #000000;
  }
}
    .success-container {
      font-family: $font-default;
      width: 100%;
      background-color: $white;
      padding: 0px ;
      height: auto;
      margin: auto;
      border-radius: 12px;
    }
    .titre {
      font-weight: bold;
      font-size: 28px;
      line-height: 160%;
      color: #000000;
    }
    .sous-titre {
      font-weight: normal;
      font-size: 16px;
      line-height: 160%;
      color: #505050;
    }
    .sous{
      font-weight: normal;
      font-size: 20px;
      line-height: 160%;
      text-align: center;
      color: #000000;
    }
    .button{
      background-color: $base-color;
      height: 57.36px;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      color: $white;
      border: 1px solid #0269AD;
      box-sizing: border-box;
      border-radius: 10px;
    }
    .input {
      width: 100%;
      background: #F1F1F1;
      border-radius: 5px;
      height: 52px;
      font-size: 18px;
      border: 1px solid #F1F1F1;
      box-sizing: border-box;
    }
    .activ{
      border: 2px solid #0269AD;
    }

    @media screen and (max-width: 1024px) and (min-width: 770px){
      .cardinal{
        width: 65%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }

    @media screen and (max-width: 769px) and (min-width: 701px){
      .cardinal{
        width: 90%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }

    @media screen and (max-width: 700px){
      .success-container{
        width: 100%;
        border-radius: 0px;
        height: 100vh;
        padding: 50px 30px !important;
        text-align: center;
      }
      .cardinal{
        width: 100%;
      }
      .titre{
        font-size: 17px;
      }

      .button{
        width: 100%;
        height: 50px;
        font-size: 17px;
      }
      .bloc{
        height: auto;
        width: 50%;
        font-size: 10px;
      }
    }
</style>
