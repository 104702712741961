<template>
<div class="about-page w-full h-screen" id="element">
  <token-admin :want-to-make-offer="activeToken" @info="retourToken" @oga="retourout"/>
  <popup-success :want-to-make-offer="activeSuccess" @oga="retourSuccess" :message="successText"/>
  <popupCharge :want-to-make-offer="charge"/>
  <header-back-recap v-if="!activeDetail"/>
  <div class="hower mr-auto ml-auto" v-if="!activeDetail">
    <recap @info="retourPayement"/>
  </div>
  <div class="details mr-auto ml-auto" v-if="activeDetail">
    <details-souscription :donnes="assurance" @oga="retourDetail"/>
  </div>
  {{donnee}}
</div>
</template>

<script>
import recap from '@/components/cedro/recapitulation/recapTemplate'
import headerBackRecap from '@/components/helper/headerBackRecap'
import popupSuccess from '@/components/popup/popupSuccess'
import detailsSouscription from '@/components/cedro/souscription/detailsSouscription'
import http from '@/plugins/http'
import apiroutes from '@/router/api-routes'
import locality from '@/components/cedro/jsonWord/locality.json'
import status from '@/components/cedro/jsonWord/social-status.json'
import activity from '@/components/cedro/jsonWord/activity.json'
import profession from '@/components/cedro/jsonWord/profession.json'
import brok from '@/components/cedro/jsonWord/intermediates.json'
import popupCharge from '@/components/popup/popupChargePaye'
import countrie from '@/components/cedro/jsonWord/countries.json'
import tokenAdmin from '@/components/popup/tokenAdmin'

export default {
  name: 'index',
  components: {
    recap,
    headerBackRecap,
    popupSuccess,
    detailsSouscription,
    popupCharge,
    tokenAdmin
  },

  props: {
    width: {
      type: String,
      default: '30%'
    },
    retour: {
      type: Number,
      default: null
    },
    suivant: {
      type: Number,
      default: null
    }
  },

  data () {
    return {
      position: 1,
      activeDevis: false,
      successText: 'Vous avez souscrit avec succès à une assurance automobile chez nous. Nous vous remercions pour votre confiance',
      activeSuccess: false,
      activeDetail: false,
      devis: null,
      permis: null,
      transactionId: '',
      assurance: null,
      charge: false,
      carteIdent: '',
      passport: '',
      activeToken: false,
      body: null,
      user: null
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    donnee: function () {

    }
  },

  mounted () {
    this.smooth()
    this.devis = this.$store.getters.trackFirstDevis

    this.user = this.parseJwt(this.$store.getters.tokenState)

    this.allTreatment()
    if (this.devis.type === 'Auto') {
      this.convertirbase64(this.devis.permis.picture, 'permis')
      this.convertirbase64(this.devis.user.picture, 'ident')
    }
    if (this.devis.type === 'Moto') {
      this.convertirbase64(this.devis.user.picture, 'ident')
    }
    if (this.devis.type === 'Voyage') {
      this.convertirbase64(this.devis.user.picture, 'ident')
    }
    if (this.devis.type === 'Mrh') {
      this.convertirbase64(this.devis.user.picture, 'ident')
    }
  },

  methods: {
    parseJwt (token) {
      const base64Url = token.split('.')[1]
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      }).join(''))
      return JSON.parse(jsonPayload)
    },

    retourToken (answer) {
      this.transactionId = parseInt(answer)
      this.save()
    },

    retourout (answer) {
      this.activeToken = answer
    },

    retourDetail () {
      this.$router.push('/souscription')
    },

    smooth () {
      const element = document.getElementById('element')
      // element.scrollIntoView(true)
      element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
    },

    retourPayement () {
      this.kkiaPay()
    },

    kkiaPay () {
      if (this.$store.getters.userConcern == null) {
        // this.save()
        const body = this.body
        if (this.user !== null) {
          body.userId = this.user.id
        }
        // console.log('kkiapay data', body)
        // eslint-disable-next-line no-undef
        openKkiapayWidget({
          // amount: 1,
          amount: this.devis.devis.computed,
          position: 'center',
          callback: '',
          data: body,
          key: 'cd9317d50d7a3794fed6cd64e6440dd277bb93d8'
        })

        // eslint-disable-next-line no-undef
        addSuccessListener(response => {
          this.transactionId = response.transactionId
          this.save()
        })
      } else {
        this.activeToken = true
      }
    },

    retourSuccess (answer) {
      this.activeSuccess = answer
      this.activeDetail = true
    },

    save () {
      this.body.paymentProof = this.transactionId
      this.charge = true
      if (this.devis.type === 'Auto') {
        this.saveAuto()
      }
      if (this.devis.type === 'Moto') {
        this.saveMoto()
      }
      if (this.devis.type === 'Voyage') {
        this.saveVoyage()
      }
      if (this.devis.type === 'Mrh') {
        this.saveMrh()
      }
    },

    success () {
      this.activeSuccess = true
      this.$store.dispatch('position', 1)
      this.$store.dispatch('saveFirstDevis', null)
      this.$store.dispatch('deleteDevis', this.devis.id)
      this.saveImage()
      this.saveDevis()
    },

    saveDevis () {
      const devis = this.$store.getters.allDevis
      http.post(apiroutes.baseURL + apiroutes.saveDevis, {
        estimate: {
          allDevis: devis
        }
      })
        .then(response => {
          // console.log('success Devis from api')
          // console.log(response.data)
        })
        .catch(error => {
          console.log('erreur')
          console.log(error)
        })
    },

    convertirbase64 (value, index) {
      let image = ''
      const reader = new FileReader()
      reader.onload = e => {
        image = e.target.result
        if (index === 'permi') {
          this.permis = image
        }
        if (index === 'ident') {
          this.carteIdent = image
        }
        if (index === 'passport') {
          this.passport = image
        }
      }
      reader.readAsDataURL(value)
    },

    saveImage () {
      http.post(apiroutes.baseURL + apiroutes.saveImage, {
        id: this.assurance.id,
        carImage: [
        ],
        greyCard: this.permis,
        leftAttestation: '',
        subscriberIdCard: this.carteIdent,
        idCardFile: ''
      })
        .then(response => {
          // console.log('successss imagesss')
          // console.log(response)
        })
        .catch(error => {
          console.log('erreur images')
          console.log(error)
        })
    },

    saveMrh () {
      if (this.$store.getters.userConcern === null) {
        http.post(apiroutes.baseURL + apiroutes.createSouscription + '/mrh', this.body)
          .then(response => {
            this.assurance = response.data
            this.success()
            this.charge = false
          })
          .catch(error => {
            console.log('erreur')
            console.log(error)
            this.charge = false
          })
      } else {
        // for Admin
        this.body.clientId = this.$store.getters.userConcern.api.id
        http.post(apiroutes.baseURL + apiroutes.createSouscriptionAdmin + '/mrh', this.body)
          .then(response => {
            // console.log('successssssssssssssss')
            // console.log(response.data)
            this.assurance = response.data
            this.success()
            this.charge = false
          })
          .catch(error => {
            console.log('erreur')
            console.log(error)
            this.charge = false
          })
      }
    },

    saveVoyage () {
      if (this.$store.getters.userConcern === null) {
        http.post(apiroutes.baseURL + apiroutes.createSouscription + '/voyage', this.body)
          .then(response => {
            // console.log('successssssssssssssss')
            // console.log(response.data)
            this.assurance = response.data
            this.success()
            this.charge = false
          })
          .catch(error => {
            console.log('erreur')
            console.log(error)
            this.charge = false
          })
      } else {
        // for admin
        this.body.clientId = this.$store.getters.userConcern.api.id
        http.post(apiroutes.baseURL + apiroutes.createSouscriptionAdmin + '/voyage', this.body)
          .then(response => {
            // console.log('successssssssssssssss')
            // console.log(response.data)
            this.assurance = response.data
            this.success()
            this.charge = false
          })
          .catch(error => {
            console.log('erreur')
            console.log(error)
            this.charge = false
          })
      }
    },

    saveMoto () {
      if (this.$store.getters.userConcern === null) {
        http.post(apiroutes.baseURL + apiroutes.createSouscription + '/moto', this.body)
          .then(response => {
            // console.log('successssssssssssssss')
            // console.log(response.data)
            this.assurance = response.data
            this.success()
            this.charge = false
          })
          .catch(error => {
            console.log('erreur')
            console.log(error)
            this.charge = false
          })
      } else {
        // for admin
        this.body.clientId = this.$store.getters.userConcern.api.id
        http.post(apiroutes.baseURL + apiroutes.createSouscriptionAdmin + '/moto', this.body)
          .then(response => {
            // console.log('successssssssssssssss')
            // console.log(response.data)
            this.assurance = response.data
            this.success()
            this.charge = false
          })
          .catch(error => {
            console.log('erreur')
            console.log(error)
            this.charge = false
          })
      }
    },

    saveAuto () {
      if (this.$store.getters.userConcern === null) {
        http.post(apiroutes.baseURL + apiroutes.createSouscription + '/auto', this.body)
          .then(response => {
            // console.log('successssssssssssssss')
            // console.log(response.data)
            this.assurance = response.data
            this.success()
            this.charge = false
          })
          .catch(error => {
            console.log('erreur')
            console.log(error)
            this.charge = false
          })
      } else {
        // for admin
        this.body.clientId = this.$store.getters.userConcern.api.id
        http.post(apiroutes.baseURL + apiroutes.createSouscriptionAdmin + '/auto', this.body)
          .then(response => {
            // console.log('successssssssssssssss')
            // console.log(response.data)
            this.assurance = response.data
            this.success()
            this.charge = false
          })
          .catch(error => {
            console.log('erreur')
            console.log(error)
            this.charge = false
          })
      }
    },

    allTreatment () {
      if (this.devis.type === 'Auto') {
        let fuel = false
        let protection = true
        if (this.devis.carburant !== 'Essence') {
          fuel = true
        }
        if (this.devis.protection !== 'oui') {
          protection = false
        }

        const str1 = this.devis.devis.compute
        const i = str1.indexOf(' ')
        const firstTerm = i === -1 ? str1 : str1.substring(0, i)

        const cedeao = this.devis.devis.values['$primes-auto-specs.cedeao']

        const acces = this.devis.devis.values['$primes-auto-specs.Accessoire']
        const taxe = this.devis.devis.values['$primes-auto-specs.Taxe']
        const fga = this.devis.devis.values['$primes-auto-specs.FGA']
        let indivAccident = 0.0

        if (this.devis.protection === 'non') {
          indivAccident = this.devis.devis.values.IndividuelleConducteur
        } else {
          indivAccident = this.devis.devis.values.ProtectionChauffeur
        }

        let zone = null

        for (const item in locality) {
          if (locality[item].label === this.devis.zone) {
            zone = locality[item]
          }
        }

        let statusSocial = null

        for (const item in status) {
          if (status[item].label === this.devis.socioStatus) {
            statusSocial = status[item]
          }
        }

        let activite = null

        for (const item in activity) {
          if (activity[item].label === this.devis.user.activity) {
            activite = activity[item]
          }
        }

        let fonction = null

        for (const item in profession) {
          if (profession[item].label === this.devis.user.profession) {
            fonction = profession[item]
          }
        }
        let intermediate = {
          id: 0,
          label: '',
          type: ''
        }

        if (this.devis.user.broker !== null) {
          for (const item in brok) {
            if (brok[item].label === this.devis.user.broker) {
              intermediate = brok[item]
            }
          }
        }

        let dure = '12month'
        if (this.devis.dure === '6 Mois') {
          dure = '6month'
        }

        this.body = {
          duration: dure,
          data: {
            subscriberName: this.devis.user.fullName,
            phoneNumber: this.devis.user.phone,
            email: this.devis.user.email,
            periodiquePlaque: this.devis.provisoir,
            birthday: new Date(this.devis.user.birthday).getTime(),
            subscriberProfession: this.devis.user.profession,
            carRegistration: this.devis.plaque,
            carBrand: this.devis.marque,
            carModel: this.devis.model,
            carColor: '',
            isDieselFuel: fuel,
            carSeat: this.devis.place,
            fiscalPower: parseInt(this.devis.puissance),
            carCirculationDate: new Date(this.devis.valeur.dateMiseEnCirculation).getTime(),
            carAmount: parseInt(this.devis.valeur.valeurVehicule),
            isFromFactory: this.devis.valeur.toutRisque,
            duration: 12,
            startDate: new Date(this.devis.valeur.souscriptionDate).getTime(),
            protectConductor: protection,
            carImage: [],
            greyCard: '',
            leftAttestation: '',
            createdAt: new Date().getTime(),
            carFrameNumber: this.devis.valeur.chassi,
            deliveryAddress: {
              name: '',
              city: '',
              district: ''
            },
            financeData: {
              rcNet: parseInt(firstTerm.replace('(((', '')),
              primeCedao: parseInt(cedeao),
              primePilot: parseInt(indivAccident),
              accessory: parseInt(acces),
              tax: taxe,
              fga: fga,
              primeTTC: parseInt(this.devis.devis.computed),
              indivAccident: 0.0
            },
            location: zone,
            activity: activite,
            social: statusSocial,
            profession: fonction,
            intermediate: intermediate,
            driverCardNumber: this.devis.permis.numero,
            driverCardDate: new Date(this.devis.permisDate).getTime(),
            carEngineNumber: 'ILLISIBLE',
            leftInsuranceNumber: '',
            hasLeftInsurance: false,
            type: 'auto'
          },
          paid: true,
          productId: 1,
          paymentProof: this.transactionId
        }
      }

      if (this.devis.type === 'Moto') {
        const str1 = this.devis.devis.compute
        const i = str1.indexOf(' ')
        const firstTerm = i === -1 ? str1 : str1.substring(0, i)

        const acces = this.devis.devis.values['$primes-moto-specs.Accessoire']
        const taxe = this.devis.devis.values['$primes-moto-specs.Taxe']
        const fga = this.devis.devis.values['$primes-moto-specs.FGA']

        let zone = null

        for (const item in locality) {
          if (locality[item].label === this.devis.autre.zone) {
            zone = locality[item]
          }
        }

        let statusSocial = null

        for (const item in status) {
          if (status[item].label === this.devis.autre.statusSocial) {
            statusSocial = status[item]
          }
        }

        let activite = null

        for (const item in activity) {
          if (activity[item].label === this.devis.user.activity) {
            activite = activity[item]
          }
        }

        let fonction = null

        for (const item in profession) {
          if (profession[item].label === this.devis.user.profession) {
            fonction = profession[item]
          }
        }
        let intermediate = {
          id: 0,
          label: '',
          type: ''
        }

        if (this.devis.user.broker !== null) {
          for (const item in brok) {
            if (brok[item].label === this.devis.user.broker) {
              intermediate = brok[item]
            }
          }
        }

        let power = 0
        if (this.devis.model.substr(0, 1).toLowerCase() === 'c') {
          power = 75
        } else if (this.devis.model.substr(0, 1).toLowerCase() === 'v') {
          power = 125
        } else if (this.devis.model.substr(0, 1).toLowerCase() === 't') {
          power = 125
        } else if (this.devis.model.substr(0, 1).toLowerCase() === 's') {
          power = 250
        } else if (this.devis.model.substr(0, 1).toLowerCase() === 'm') {
          if (this.devis.puissance === '0 à 2 CV') {
            power = 2
          } else {
            power = 3
          }
        }

        this.body = {
          duration: '12month',
          data: {
            subscriberName: this.devis.user.fullName,
            phoneNumber: this.devis.user.phone,
            email: this.devis.user.email,
            birthday: new Date(this.devis.user.birthday).getTime(),
            subscriberProfession: this.devis.user.profession,
            duration: 12,
            startDate: new Date(this.devis.autre.souscriptionDate).getTime(),
            createdAt: new Date().getTime(),
            motorBrand: this.devis.marque,
            motorCat: this.devis.model.substr(0, 1).toLowerCase(),
            motorPower: power,
            subscriberIdCard: '',
            type: 'moto',
            motoRegistration: this.devis.autre.plaque,
            motoCirculationDate: new Date(this.devis.autre.dateMiseEnCirculation).getTime(),
            idCardNumber: this.devis.autre.cardId,
            deliveryAddress: {
              name: '',
              city: '',
              district: ''
            },
            financeData: {
              rcNet: parseInt(firstTerm.replace('((', '')),
              primeCedao: 0.0,
              primePilot: 0.0,
              accessory: parseInt(acces),
              tax: taxe,
              fga: fga,
              primeTTC: parseInt(this.devis.devis.computed),
              indivAccident: this.devis.devis.values.IndividuelleAccident
            },
            location: zone,
            activity: activite,
            social: statusSocial,
            profession: fonction,
            intermediate: intermediate,
            leftInsuranceNumber: '',
            hasLeftInsurance: false
          },
          paid: true,
          productId: 2,
          paymentProof: this.transactionId
        }
        console.log(this.body)
      }

      if (this.devis.type === 'Voyage') {
        let arrive = null
        let depart = null
        for (const item in countrie) {
          if (countrie[item].name === this.devis.destination) {
            arrive = countrie[item]
          }
          if (countrie[item].name === this.devis.pays) {
            depart = countrie[item]
          }
        }

        let haveChronicDisease = false
        let haveMedicalTreatment = false
        let hasDentalCare = false

        if (this.devis.sante.dent === 'oui') {
          hasDentalCare = true
        }
        if (this.devis.sante.health === 'oui') {
          haveChronicDisease = true
        }
        if (this.devis.sante.traitment === 'oui') {
          haveMedicalTreatment = true
        }

        const str1 = this.devis.devis.compute
        const i = str1.indexOf(' ')
        const firstTerm = i === -1 ? str1 : str1.substring(0, i)

        const acces = this.devis.devis.values['$primes-voyage-specs.Accessoire']
        const taxe = this.devis.devis.values['$primes-voyage-specs.Taxe']

        let statusSocial = null

        for (const item in status) {
          if (status[item].label === this.devis.autre.statusSocial) {
            statusSocial = status[item]
          }
        }

        let activite = null

        for (const item in activity) {
          if (activity[item].label === this.devis.user.activity) {
            activite = activity[item]
          }
        }

        let fonction = null

        for (const item in profession) {
          if (profession[item].label === this.devis.user.profession) {
            fonction = profession[item]
          }
        }
        let intermediate = {
          id: 0,
          label: '',
          type: ''
        }

        if (this.devis.user.broker !== null) {
          for (const item in brok) {
            if (brok[item].label === this.devis.user.broker) {
              intermediate = brok[item]
            }
          }
        }

        const date = new Date(this.devis.depart).getTime()
        const dateFin = new Date(date - (parseInt(this.devis.dure) * 24 * 60 * 60 * 1000)).getTime()

        this.body = {
          duration: this.devis.dure + 'day',
          data: {
            subscriberName: this.devis.user.fullName,
            phoneNumber: this.devis.user.phone,
            email: this.devis.user.email,
            birthday: new Date(this.devis.naissance).getTime(),
            birthDay: new Date(this.devis.naissance).getTime(),
            subscriberProfession: this.devis.user.profession,
            startDate: new Date(this.devis.depart).getTime(),
            createdAt: new Date().getTime(),
            country: arrive,
            ownerCountry: depart,
            endDate: dateFin,
            initialDate: new Date(this.devis.depart).getTime(),
            old: new Date().getFullYear() - new Date(this.devis.naissance).getFullYear(),
            haveChronicDisease: haveChronicDisease,
            haveMedicalTreatment: haveMedicalTreatment,
            hasDentalCare: hasDentalCare,
            subscriberIdCard: '',
            type: 'trip',
            passportNumber: this.devis.autre.cardId,
            passportExpDate: this.devis.autre.expiration,
            deliveryAddress: {
              name: '',
              city: '',
              district: ''
            },
            financeData: {
              rcNet: parseInt(firstTerm.replace('((', '')),
              primeCedao: 0.0,
              primePilot: 0.0,
              accessory: parseInt(acces),
              tax: taxe,
              fga: 0,
              primeTTC: parseInt(this.devis.devis.computed),
              indivAccident: 0
            },
            activity: activite,
            social: statusSocial,
            profession: fonction,
            intermediate: intermediate,
            leftInsuranceNumber: '',
            hasLeftInsurance: false,
            isTreated: false
          },
          paid: true,
          productId: 4,
          paymentProof: this.transactionId
        }
      }

      if (this.devis.type === 'Mrh') {
        let statusSocial = null

        for (const item in status) {
          if (status[item].label === this.devis.autre.statusSocial) {
            statusSocial = status[item]
          }
        }

        let activite = null

        for (const item in activity) {
          if (activity[item].label === this.devis.user.activity) {
            activite = activity[item]
          }
        }

        let fonction = null

        for (const item in profession) {
          if (profession[item].label === this.devis.user.profession) {
            fonction = profession[item]
          }
        }
        let intermediate = {
          id: 0,
          label: '',
          type: ''
        }

        if (this.devis.user.broker !== null) {
          for (const item in brok) {
            if (brok[item].label === this.devis.user.broker) {
              intermediate = brok[item]
            }
          }
        }

        let tech = true
        let garentie = false
        if (this.devis.informatique.materielInformatique !== 'oui') {
          tech = false
        }
        if (this.devis.informatique.garentie !== 'oui') {
          garentie = true
        }

        let habitation = null
        if (this.devis.model.model === 'Une Villa') {
          habitation = 'villa'
        }
        if (this.devis.model.model === 'Un Duplex') {
          habitation = 'duplex'
        }
        if (this.devis.model.model === 'Un Appartement') {
          habitation = 'appartement'
        }
        this.body = {
          duration: '12month',
          data: {
            subscriberName: this.devis.user.fullName,
            phoneNumber: this.devis.user.phone,
            email: this.devis.user.email,
            birthday: new Date(this.devis.user.birthday).getTime(),
            subscriberProfession: this.devis.user.profession,
            duration: 12,
            startDate: new Date(this.devis.autre.souscriptionDate).getTime(),
            createdAt: new Date().getTime(),
            tenantType: this.devis.occupation,
            habitationType: habitation,
            ville: this.devis.adresse.ville,
            district: this.devis.adresse.quartier,
            localisationDetails: this.devis.adresse.localisation,
            buildingValue: this.devis.valeur.valeurBatiment,
            rent: this.devis.valeur.loyer,
            contentValue: this.devis.valeur.valeurContenu,
            techValue: this.devis.informatique.valeur,
            numberPiece: this.devis.bien.piece,
            numberOfAnimals: this.devis.bien.animaux,
            buildingArea: parseInt(this.devis.bien.superficie),
            haveTech: tech,
            idCardFile: '',
            wantGuarantie: garentie,
            subscriberIdCard: '',
            type: 'dwelling',
            idCardNumber: this.devis.autre.cardId,
            deliveryAddress: {
              name: '',
              city: '',
              district: ''
            },
            financeData: {
              rcNet: 0,
              primeCedao: 0.0,
              primePilot: 0.0,
              accessory: 0,
              tax: 0,
              fga: 0,
              indivAccident: 0,
              primeTTC: parseInt(this.devis.devis.computed)
            },
            activity: activite,
            social: statusSocial,
            profession: fonction,
            intermediate: intermediate,
            leftInsuranceNumber: '',
            hasLeftInsurance: false
          },
          paid: true,
          productId: 3,
          paymentProof: this.transactionId
        }
      }

      if (this.body.data.profession === null) {
        this.body.data.profession = {
          id: '00',
          label: ''
        }
      }

      if (this.body.data.activity === null) {
        this.body.data.activity = {
          id: '00',
          label: ''
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/style/sass/variables";

.about-page{
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  font-family: $font-default;
  background-color: #F5F5F5;
  height: 100%;
  padding-bottom: 300px;
}
.hower{
  width: 65%;
  background-color: #fff;
  padding: 50px 230px 50px 230px;
  border-radius: 20px;
  margin-top: -100px;
}
.details{
  width: 65%;
  padding-top: 50px;
}
@media screen and (max-width: 1024px) and (min-width: 770px){
  .hower{
    width: 67%;
    background-color: #fff;
    padding: 40px;
    border-radius: 20px;
  }
  .details{
    width: 90%;
  }
}
@media screen and (max-width: 769px) and (min-width: 701px) {
  .hower{
    width: 85%;
    background-color: #fff;
    padding: 40px;
    border-radius: 20px;
  }
  .details{
    width: 90%;
  }
}
@media screen and (max-width: 700px){
  .hower{
    width: 93%;
    background-color: #fff;
    padding: 20px;
    border-radius: 20px;
    margin-top: -30px;
  }
  .details{
    width: 90%;
  }
}
</style>
